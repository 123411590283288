<template>
  <div class="third">
    <Crumbs :breadList="crumbs" />
    <div class="main">
      <div class="l_box">
        <LeftRollMenu :arr="leftList" @onCakkback="onDetails"></LeftRollMenu>
      </div>
      <!-- //End -->
      <div class="r_box">
        <Details :item="item">
          <div class="link">
            <a
              :class="{
                'not-a': nextItem.title == '没有下一篇了',
                dcusor: nextItem.title == '没有下一篇了',
              }"
              href="javascript:void(0)"
              @click="onDetails(nextItem)"
              >下一篇: {{ nextItem.title }}</a
            >
          </div>
          <div class="link">
            <a
              :class="{
                'not-a': lastItem.title == '没有上一篇了',
                dcusor: lastItem.title == '没有上一篇了',
              }"
              href="javascript:void(0)"
              @click="onDetails(lastItem)"
              >上一篇: {{ lastItem.title }}</a
            >
          </div>
        </Details>
      </div>
    </div>
  </div>
</template>
<script>
import Crumbs from "@/components/crumbs/index";
import LeftRollMenu from "@/components/leftMenu/roll-index";
import Details from "@/components/details/index";
import { getTreeItem } from "@/utils/index";
export default {
  components: {
    Crumbs,
    LeftRollMenu,
    Details,
  },

  data() {
    return {
      classification: "", // 分类名称
      articleId: "", // 首次展示的文章id
      index: 0,
      // 面包屑
      crumbs: [],
      leftList: [],
      // 要展示的文章
      item: {
        content: "",
      },
      // index: 0, // 默认选中第一个
      nextItem: {
        title: "没有下一篇了",
        type: "next",
      },
      lastItem: {
        title: "没有上一篇了",
        type: "last",
      },
      parentItem: null, // 父级对象
      childrenItem: null, // 子级对象
    };
  },
  created() {
    this.classification = sessionStorage.getItem("classification"); // 获取分类名称
    this.articleId = sessionStorage.getItem("articleId"); // 获取首次要展示的文章id
    if (this.classification) {
      if (this.classification.indexOf("rightNotice") != -1) {
        let type = this.classification.split("_").pop();
        // 0 是通知  1是置顶通知 2是轮播图 3首页广告
        if (type == 0) {
          this.getRollList();
        } else if (type == 1) {
          this.getTopList();
        } else if (type == 2) {
          this.getSliderList();
        } else {
          this.getAdvList();
        }
      } else {
        this.getLeftArticle(); // 根据分类名称查询出左侧分类文章
      }
    } else {
      // 如果缓存中没有数据 默认展示左侧滚动通知
      this.getRollList();
    }
  },
  methods: {
    // 查询二级分类文章
    async getLeftArticle() {
      let res = await this.$api.second.leftArticle(this.classification);
      if (res.status == 200) {
        this.leftList = res.data.records;
      }
      this.getArticleDetails(); // 获取首次要展示的文章
    },
    // 查询右侧滚动通知
    async getRollList() {
      let res = await this.$api.home.noticeList();
      if (res.status == 200) {
        this.leftList = res.data.records;
      }
      this.getArticleDetails(); // 获取首次要展示的文章
    },
    // 查询置顶通知
    async getTopList() {
      let res = await this.$api.home.topList();
      if (res.status == 200) {
        this.leftList = res.data.records;
      }
      this.getArticleDetails(); // 获取首次要展示的文章
    },
    // 获取轮播图
    async getSliderList() {
      let res = await this.$api.home.sliderList();
      if (res.status == 200) {
        this.leftList = res.data.records;
      }
      this.getArticleDetails(); // 获取首次要展示的文章
    },
    // 获取广告位
    async getAdvList() {
      let res = await this.$api.second.getAdvList();
      if (res.status == 200) {
        this.leftList = res.data.records;
      }
      this.getArticleDetails(); // 获取首次要展示的文章
    },
    // 获取文章详情
    async getArticleDetails() {
      if (this.articleId) {
        let res = await this.$api.second.articleDetail(this.articleId);
        if (res.status == 200) {
          Object.assign(this.item, res.data);
          this.buildCrumbs();
          this.buildLastNaxt();
        }
      } else {
        this.onDetails(null, 0);
      }
    },
    // 建造面包屑
    buildCrumbs() {
      let myMenus = JSON.parse(sessionStorage.getItem("menus"));
      this.childrenItem = getTreeItem(myMenus, this.item.bizCategory.id, "id");
      this.parentItem = getTreeItem(myMenus, this.childrenItem.pid, "id");
      if (this.crumbs.length == 0) {
        this.crumbs.push({
          name: "首页",
          link: "/",
        });
        if (this.item.slider == 1) {
          // 轮播图
          this.crumbs.push({
            name: "焦点新闻",
            link: "",
          });
        } else if (this.item.adv == 1) {
          // 轮播图
          this.crumbs.push({
            name: "广告位",
            link: "",
          });
        } else {
          this.crumbs.push({
            name: this.parentItem.name,
            link:
              "/second?pid=" +
              this.parentItem.id +
              (this.childrenItem ? "&sid=" + this.parentItem.children[0].id : ""),
          });
          this.crumbs.push({
            name: this.childrenItem.name,
            link:
              "/second?pid=" +
              this.parentItem.id +
              (this.childrenItem ? "&sid=" + this.childrenItem.id : ""),
          });
          this.crumbs.push({
            name: "文章详情",
            link: "",
          });
        }
      } else {
        this.crumbs.splice(1, 1, {
          name: this.parentItem.name,
          link:
            "/second?pid=" +
            this.parentItem.id +
            (this.childrenItem ? "&sid=" + this.parentItem.children[0].id : ""),
        });
        this.crumbs.splice(2, 1, {
          name: this.childrenItem.name,
          link:
            "/second?pid=" +
            this.parentItem.id +
            (this.childrenItem ? "&sid=" + this.childrenItem.id : ""),
        });
      }
    },

    buildLastNaxt() {
      for (let i = 0; i < this.leftList.length; i++) {
        if (this.item.id == this.leftList[i].id) {
          if (i != 0) {
            Object.assign(this.lastItem, this.leftList[i - 1]);
          } else {
            Object.assign(this.$data.lastItem, this.$options.data().lastItem);
          }

          if (i + 1 < this.leftList.length) {
            Object.assign(this.nextItem, this.leftList[i + 1]);
          } else {
            Object.assign(this.$data.nextItem, this.$options.data().nextItem);
          }
          break;
        }
      }
    },
    onDetails(item, index) {
      console.log("item --- ", item);
      if (item) {
        Object.assign(this.item, item);
        this.articleId = this.item.id;
        this.getArticleDetails()
      } else {
        Object.assign(this.item, this.leftList[index]);
        this.articleId = this.item.id;
        this.getArticleDetails()
      }
      if (item && item.type == "next") {
        if (this.nextItem.title != "没有下一篇了") {
          window.scrollTo(0, 0);
        }
      }
      if (item && item.type == "last") {
        if (this.lastItem.title != "没有上一篇了") {
          window.scrollTo(0, 0);
        }
      }
      this.buildLastNaxt();
    },
  },
};
</script>
<style lang="scss">
@import "./../../assets/style/public.scss";
.third {
  .main {
    @extend %clearfix;
  }
  width: 100%;
  float: left;
  background: #f9f9f9;
  .menu-link {
    padding: 0 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    a {
      font-size: 12px;
      color: #222222;
      line-height: 40px;
      font-weight: bold;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #222222;
    line-height: 25px;
    padding-top: 30px;
    padding-bottom: 25px;
  }
  .date {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    line-height: 20px;
    margin-bottom: 35px;
  }
  .html {
    font-size: 16px;
    font-weight: 400;
    color: #222222;
    line-height: 32px;
    // pc端甲方要求不对副本样式做操作
    // p {
    //   text-indent: 0 !important;
    // }
    // img {
    //   width: 100% !important;
    //   height: 100% !important;
    // }
  }
  .btm {
    width: 100%;
    float: left;
    margin-top: 130px;
    .link {
      display: block;
      .not-a {
        color: #666666;
        &:hover {
          color: #666666;
        }
      }
      a {
        font-size: 14px;
        color: #222222;
        line-height: 32px;
        &:hover {
          color: #c01515;
        }
      }
    }
  }
  .l_box {
    min-height: 472px;
    background: #f9f9f9;
  }

  .dcusor {
    cursor: default;
  }
}
</style>
